import {
	Component,
	OnInit
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import {
	ConfirmationService,
	MenuItem,
	MessageService
} from 'primeng/api';
import { SupplierEngineerService } from '../../services/rfi/supplierEngineer.service';

@Component({
	selector: 'create-supplier-engineer',
	templateUrl: './create-supplier-engineer.component.html',
	styleUrls: ['./create-supplier-engineer.component.css'],
	providers: [MessageService]
})
export class CreateSupplierEngineerComponent implements OnInit {

	private supplierEngineerId;
	private mode;
	private isEdit;
	private supplierEngineer: any = {};
	private breadCrumItems: MenuItem[];
	private isFromDateChange = false;
	private isToDateChange = false;
	private orgFromDate;
	private orgToDate;
	private buyerList = [];
	private selectedSupplierEngineer;
	private isSubmit;

	minDate: Date | undefined;

	constructor(private activatedRoute: ActivatedRoute, private supplierEngineerService: SupplierEngineerService,
				private messageService: MessageService, private confirmationService: ConfirmationService,
				private fb: FormBuilder) {

	}

	ngOnInit(): void {
		const that = this;
		// tslint:disable-next-line:no-unused-expression
		this.supplierEngineer.isMoving = 'Moving';
		that.retrieveRouteParameter();
		const today = new Date();
		const month = today.getMonth() +1;
		const year = today.getFullYear();
		const prevMonth = (month === 0) ? 11 : month -1;
		const prevYear = (prevMonth === 11) ? year - 1 : year;
		this.minDate = new Date();
		this.minDate.setMonth(prevMonth);
		this.minDate.setFullYear(prevYear);

		this.breadCrumItems = [
			{label: 'Buyer', url: '#/buyer-supplier/buyer', target: '_self'},
			{label: 'Update Supplier Engineer', url: '#/supplier-engineer', target: '_self'},
			{label: 'Edit Supplier Engineer'}
		];
	}

	isValidDates(fromDate, toDate) {
		return moment(fromDate) < moment(toDate);
	}

	isMandatoryField() {
		let mandatory = true;

		if (this.supplierEngineer.isMoving === 'Moving') {
			mandatory = this.supplierEngineer.fromDate != null
				&& this.supplierEngineer.supplierEngineer != null;
		} else if (this.supplierEngineer.isMoving === 'Vacation') {
			mandatory = this.supplierEngineer.toDate != null
				&& this.supplierEngineer.fromDate != null
				&& this.supplierEngineer.supplierEngineer != null;
		}

		this.isSubmit = !mandatory;

		return mandatory;
	}

	private onFromDateChange() {
		this.isFromDateChange = true;
	}

	private onToDateChange() {
		this.isToDateChange = true;
	}

	private onSave() {
		const regexp = new RegExp('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$');// NOSONAR

		if (!this.isMandatoryField()) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required.'
				});

			}, 30000);
			return;
		}
		if (!regexp.test(this.supplierEngineer.supplierEngineer)) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Please enter valid supplier email address.'
				});
			}, 30);
			return;
		}

		if (!this.isFromDateChange && this.orgFromDate) {
			this.supplierEngineer.fromDate = this.orgFromDate;
		}

		if (!this.isToDateChange && this.orgToDate) {
			this.supplierEngineer.toDate = this.orgToDate;
		}

		if (this.supplierEngineer.isMoving === 'Moving') {
			this.supplierEngineer.toDate = null;
		}

		if (this.supplierEngineer.isMoving === 'Vacation' && !this.supplierEngineer.toDate) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required before proceeding. '
				});
			}, 30);
		} else if (this.supplierEngineer.isMoving === 'Moving' && !this.supplierEngineer.fromDate) {
			setTimeout(() => {
				this.messageService.add({
					life: 300000, severity: 'warn',
					summary: 'Mandatory fields are required before proceeding. '
				});
			}, 30);
		} else {
			if (this.supplierEngineer.isMoving ===
				'Vacation' && !this.isValidDates(this.supplierEngineer.fromDate, this.supplierEngineer.toDate)) {
				setTimeout(() => {
					this.messageService.add({
						life: 300000, severity: 'warn',

						summary: 'From date must be smaller than To date for Supplier Contact.'
					});
				}, 30);

				return;
			}

			this.supplierEngineer.createBy = sessionStorage.getItem('userId');
			this.supplierEngineer.updateBy = sessionStorage.getItem('userId');
			this.supplierEngineerService.saveSupplierEngineer(this.supplierEngineer).subscribe(response => {
				if (response) {
					setTimeout(() => {
						this.messageService.add({
							life: 300000, severity: 'success',
							summary: 'Supplier Engineer ID saved successfully. ' +
								'Please allow at least 15 minutes for the changes to take effect.'
						});
					}, 30);

					this.isFromDateChange = false;
					this.isToDateChange = false;
					this.orgFromDate = this.supplierEngineer.fromDate;
					this.supplierEngineer.fromDate = moment(this.supplierEngineer.fromDate).format('MM/DD/YYYY');
					if (this.supplierEngineer.toDate) {
						this.orgToDate = this.supplierEngineer.toDate;
						this.supplierEngineer.toDate = moment(this.supplierEngineer.toDate).format('MM/DD/YYYY');
						this.supplierEngineer.isMoving = 'Vacation';
					} else {
						this.supplierEngineer.isMoving = 'Moving';
					}
					this.selectedSupplierEngineer = this.supplierEngineer.supplierEngineer;
				}
			});
		}
	}

	private fetchSupplierEngineer() {
		let userId = sessionStorage.getItem('userId');
		const apsRoles = JSON.parse(sessionStorage.getItem('apsRoles'));
		if (apsRoles.includes('eDCM_IT_ADMIN') || apsRoles.includes('eDCM_Super_Buyer')) {
			userId = 'eDCM_IT_ADMIN';
		}

		this.supplierEngineerService.findSupplierEngineer(this.supplierEngineerId).subscribe(resObj => {
			this.supplierEngineer = resObj;
			this.supplierEngineer.isMoving = 'Moving';
			this.orgFromDate = this.supplierEngineer.fromDate;

			if (this.supplierEngineer.fromDate) {
				this.supplierEngineer.fromDate = moment(this.supplierEngineer.fromDate).format('MM/DD/YYYY');
			}

			if (this.supplierEngineer.toDate) {
				this.supplierEngineer.isMoving = 'Vacation';
				this.orgToDate = this.supplierEngineer.toDate;
				this.supplierEngineer.toDate = moment(this.supplierEngineer.toDate).format('MM/DD/YYYY');
			}
		});
	}

	private onBuyerChange() {
		this.supplierEngineer.supplierEngineer = this.selectedSupplierEngineer;
	}

	private retrieveRouteParameter() {
		this.activatedRoute.paramMap.subscribe(params => {
			this.supplierEngineerId = params.get('supplierEngineerId');
			this.mode = params.get('mode');
			this.isEdit = this.mode === 'edit';

			const addEdit = this.isEdit ? 'Edit Update Supplier Engineer' : 'Add Update Supplier Engineer';

			if (this.isEdit) {
				this.fetchSupplierEngineer();
			}

		});
	}
}
