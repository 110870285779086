/**This file contains a constant object that has properties that are necessary
 * for the build found in the `production` configuration in `angular.json`.
 */
export const environment = {
	envName: 'prod',
	production: false,
	ADFS_URL: 'https://corp.sts.ford.com/adfs/oauth2/authorize',
	ADFS_CLIENT_ID: 'urn:edcm_preprod:clientid:web_edcm_preprod_website_gcp:prod',
	ADFS_RESOURCE: 'urn:edcm_preprod:resource:web_edcm_preprod_website_gcp:prod',
	BACKEND_URL: 'https://api.pd01e.gcp.ford.com/'

	// include development api base url string here as a property
};
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
